/* .dashboard-component {
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    padding: 20px;
}

.dashboard-component-title {
    font-size: 20px;
    font-weight: 800;
    color: rgba(0, 0, 0, 1);
    text-align: initial;
    margin: 0 20px;
    padding: 5px 0;
}

.dashboard-content {
    height: calc(100vh - 100px); 
    margin: 0 20px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;

}



 */

.legitamacy-yellow {
  background-color: #fba500;
  color: #fff5d9;
  padding: 5px 10px;
  border-radius: 20px;
  height: 28px;
}

.legitamacy-green {
  background-color: #128064;
  color: #c6ffcf;
  padding: 5px 10px;
  border-radius: 20px;
  height: 28px;
}

.legitamacy-red {
  background-color: rgb(243, 82, 82);
  color: #ffe0eb;
  padding: 5px 10px;
  border-radius: 20px;
  height: 28px;
}

.status-red {
  background-color: #ffe0eb;
  color: rgb(243, 82, 82);
  border: 1px solid rgb(243, 82, 82);
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}

.status-blue {
  background-color: rgb(115, 164, 237);
  color: rgb(0, 98, 245);
  border: 1px solid rgb(0, 98, 245);
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}

.status-yellow {
  background-color: #fff5d9;
  color: #fba500;
  border: 1px solid #fba500;
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}
.status-dark-yellow {
  background-color: #fba500;
  color: #fff5d9;
  border: 1px solid #fba500;
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}
.status-dark-green {
  background-color: #0a5c36;
  color: #daff0a;
  border: 1px solid #daff0a;
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}
.status-grey {
  background-color: #dbdbdb;
  color: #131313;
  border: 1px solid #131313;
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}
.status-green {
  background-color: #c6ffcf;
  color: #128064;
  border: 1px solid #128064;
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}
.status-blue {
  background-color: #77ccff;
  color: #0000ff;
  border: 1px solid #0000ff;
  padding: 5px 10px;
  border-radius: 20px;
  height: 27px;
}

.dashboard-component {
  display: flex;
  flex-direction: column;
}

.dashboard-info-bar {
  height: 100px;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-info-content {
  background-color: #ffffff;
  width: 22.5%;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-header-content {
  text-align: left;
  margin: 15px 0;
  font-size: 22px;
  font-weight: 600;
}

.dashboard-table-content {
  height: 625px;
  width: 100%;
}
.dashboard-icon-div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.dashboard-info-text-div {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dashboard-info-text-header {
  font-size: 16px;
  font-weight: 400;
  color: #718ebf;
}
.dashboard-info-text-value {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}
.ag-header-cell-text {
  color: #396aff;
  font-size: 19px;
}
.ag-header-cell-label {
  justify-content: center !important;
  text-align: center !important;
}
.ag-header-cell-resize {
  display: none !important;
}
.mobile-leads-con {
  width: 100%;
  border: none;
  height: 70px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  padding: 10px;
}
.mobile-leads-con-header {
  width: 100%;
  border: none;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.outer-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  justify-content: center;
  padding: 10px;
  overflow-y: scroll;
}
.leads-main-mobile-con {
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 10px;
}
.image-head-container {
  display: flex;
  padding: 0px 10px;
  gap: 10px;
  justify-content: center;
}
.header-text-con {
  font-size: 26px;
  font-weight: 600;
  line-height: 27.36px;
  text-align: left;
}
/* .ag-body.ag-layout-normal {
  overflow: auto !important;
} */
.search-input {
  height: 32px;
  margin: 15px 0px;
  border: 1px solid grey;
  border-radius: 5px;
  padding-left: 30px;
}
.search-container {
  position: relative;
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  left: 5px;
  pointer-events: none;
}

/* .search-input {
 
} */

.search-input-mobile {
  height: 28px;
  width: 100%;
  margin: 10px 0px 0px 0px;
  border: 1px solid grey;
  border-radius: 5px;
  padding-left: 30px;
}
.search-outer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 11px;
}
.manual-status {
  border-radius: 20px;
  align-items: center;
  display: flex;
  font-size: 12px;
  height: 25px;
  font-weight: 500;
  background-color: #ffdfeb;
  color: #800000;
  border: 1px solid #800000;
  padding: 5px 10px;
}
.auto-status {
  border-radius: 20px;
  align-items: center;
  display: flex;
  font-size: 12px;
  height: 25px;
  font-weight: 500;
  background-color: #90ee90;
  color: #006400;
  border: 1px solid #006400;
  padding: 5px 10px;
}
