.settings-lookup-navbar-text {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  background-color: #ffffff;
  text-decoration: none;
  padding: 1px 5px 1px 5px;
  cursor: pointer;
}
.settings-lookup-navbar-text-active {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #1814f3;
  text-decoration: none;
  background-color: #ffffff;
  padding: 1px 5px 1px 5px;
}
.sticky-header {
  display: flex;
  position: sticky;
  top: 50px;
  left: 50px;
  margin-top: 0px;
}
.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}
.ag-theme-quartz .ag-center-cols-container {
  width: auto !important;
}
