.header-container {
  max-height: 100px;
  background: #ffffff;
  /* padding: 30px 20px; */
  border-bottom: 1px solid #e6eff5;
}
.header-content-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.header-content-holder > h1 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #343c6a;
  margin: 0;
}
.header-iconholder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.header-iconholder > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background: #c4c4c4;
}

.header-bellicon-holder {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #f5f7fa;
}

.header-container {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border: 0px;
}
.breadcrumb a {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
}
.employee-list-header-text {
  margin-left: 3px;
}
.employee-list-header-text > p {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-wrap: nowrap;
  /* margin-top: -18px; */
  /* commented by vivek */
}
.header-title-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  margin-left: 10px;
  width: auto;
  word-wrap: normal;
}
.header-title-text-wrap {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000000;
  text-wrap: nowrap;
}
.header-title-text-wrap-mob {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  text-wrap: nowrap;
}
.nav-link {
  color: #000000;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  height: 50px;
  display: flex;
  align-items: center;
}
.navbar-nav {
  background-color: rgba(36, 44, 68, 1);
  height: 100%;
}

.toggle-icon {
  color: #ffffff;
  font-size: 30px;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.nav-link:hover {
  border-bottom: 3px solid #3fc28a;
  transition: all 0.5 ease-in;
}
.nav-link:hover {
  transition: all 0.5 ease-in;
  color: #3fc28a !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
}
.hr-line {
  color: white;
  width: 30px;
  font-size: 20px;
  opacity: 1;
  transform: rotate(90deg);
}
.header-dropdown-icon {
  color: black;
  font-size: 20px;
}
.header-sidebar-main {
  position: absolute;
  width: 100%;
  /* margin-top: 5vh; */
  min-height: 95vh;
  z-index: 9999;
  top: 65px;
  left: 0px;

  background-color: #000000;
  padding: 5px;
  transition: all 0.5s ease-in;
}
.nav-link.active {
  color: #ffffff !important;
  transition: all 0.5 ease-in;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  border-bottom: 3px solid #3fc28a;
}
.profile-container {
  background-color: transparent !important;
  cursor: pointer;
}
.profile-container > .dropdown-toggle::after {
  display: none !important;
}
.logo-show-head {
  display: none;
}
.sidebar-list-inner-container.active > .logo-hide-head {
  display: none;
}
.sidebar-list-inner-container.active > .logo-show-head {
  display: block;
}
.header-title-text-con {
  width: min-content !important;
  margin-right: 10px !important;
}
.header-nav-profile-image {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #3fc28a;
}
.employee-list-select-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-list-profile-holder {
  display: flex;
  align-items: center;
}

.header-dropdown-icon {
  color: black;
  font-size: 20px;
}
.employee-list-header-dropdown-icon {
  margin-top: -10px;
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .employee-list-header-text > p {
    display: none;
  }
  .employee-list-header-text {
    margin-left: -5px;
  }
}

.sidebar-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40px;
}
.sidebar-list-inner-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 40px;
}
.sidebar-list-inner-container.active {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 40px;
}
.sidebar-logo-container-header {
  display: inline-flex;
  margin: 2px 2px;
  gap: 10px;
  align-items: center;
}


p{
  margin-top:revert;
}

@media screen and (max-width: 768px) {
  .header-container{
    height:65px;    
  }
}

@media screen and (min-width: 1024px) {
  .header-container{
    height:80px;  
    padding:10px;  
  }
  .header-title{
    font-size: 24px;
    font-weight: 600;
    
  }
}
