.chat-component {
  padding: 15px;
  height: -webkit-fill-available;
  border-radius: 20px;
  background-color: #ece5dd;
}
.list-add-bttn {
  height: auto;
  width: auto;
  border: 1px solid #9d2764;
  background-color: #ffffff;
  border-radius: 5px;
  color: #9d2764;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 5px;
  cursor: pointer;
}

.writing-parameter-list-content-inner-text {
  display: flex;
  justify-content: flex-start;
  margin: none;
  align-self: baseline;
  margin-bottom: 0px;
  gap: 10px;
}

.chat-content {
  height: calc(100vh - 94px);
  border-radius: 5px;
  position: relative;
  color: black;
}

.profile-container-query-chat-screen-Container {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #f4f5f7;
  background-color: #128c7e;
  padding-bottom: 5px;
  padding-left: 5px;
  justify-content: space-between;
}
.profile-container-query-chat-screen {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #f4f5f7;
  padding-bottom: 5px;
  padding-left: 5px;
  justify-content: space-between;
}
.profile-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 0px 5px 5px;
}

.profile-image-query-chat-screen {
  width: 57px;
  height: 57px;
  border-radius: 50%;
}
.profile-name-container-query-chat-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-name-text-query-chat-screen {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.profile-student-id-text-query-chat-screen {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.query-chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow-y: scroll;
  padding-top: 10px;
  height: calc(100vh - 280px);
  overflow-anchor: auto;
  background-color: #ece5dd;
}
.query-chat-container::-webkit-scrollbar {
  width: 0;
}
.send-message-text-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.receive-message-text-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.send-message-time-text {
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
}

.receive-message-time-text {
  text-align: left;
  padding-left: 10px;
  font-size: 12px;
}
.send-message-text-inner-container {
  width: fit-content;
  min-width: 30%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  background-color: #dcf8c6;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}
.send-message-text-inner-container-with-image {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}
.receive-message-text-inner-container {
  width: fit-content;
  min-width: 30%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.send-message-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 5px;
  overflow-wrap: break-word;
}
.receive-message-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 5px;
  overflow-wrap: break-word;
}

.query-chatlist-message-input-container {
  position: fixed;
  bottom: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
  background-color: transparent;
  border-radius: 20px;
  position: absolute;
  gap: 15px;
}

.query-chatlist-message-input {
  min-height: 42px;
  max-height: 100px;
  box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.3);
  overflow-y: auto !important;
  vertical-align: middle;
  width: 96%;
  background-color: white;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #050505;
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 10px;
  resize: none;
  outline: none;
}
.query-chatlist-message-attach-input-icon {
  position: absolute;
  right: 70px;
  cursor: pointer;
}
.query-chatlist-message-input::-webkit-scrollbar {
  width: 0;
}
.query-chatlist-message-input::placeholder {
  position: absolute;
  left: 20px;
  top: 10px;
}

.chat-message-input-send-button {
  background: #128c7e;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-head-Text {
  color: #1814f3;
  font-size: 19px;
  font-weight: 400;
}
.option {
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  border-radius: 2px;
}

.option:hover {
  background-color: #f2f2f2 !important;
}
.circle {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d1e1ed;
  color: #110df0;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
}

.modal-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000000 !important;
  padding: 10px;
}
.modal-body::-webkit-scrollbar {
  width: 3px;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scrollbar-width: thin;
}

.submit-button-container {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px;
}
.faq-loader {
  background: #acbfa4;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .onclick-hover:hover {
  border-radius: 50%;
  background-color: #9d2764;
} */
/* Styles for tablets */
@media screen and (max-width: 800px) {
  .content-div {
    padding: 0px;
    background-color: #ece5dd;
  }
  .profile-container {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0px;
  }

  .profile-student-id-text-query-chat-screen {
    text-wrap: nowrap;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .profile-container-query-chat-screen-Container {
    display: flex;
    border-bottom: 1px solid #f4f5f7;
    background-color: #128c7e;
    padding-bottom: 5px;
    padding-left: 5px;
    justify-content: space-between;
  }
  .list-add-bttn {
    height: auto;
    width: auto;
    border: 1px solid #9d2764;
    background-color: #ffffff;
    border-radius: 5px;
    color: #9d2764;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 5px;
    cursor: pointer;
  }

  .Right-side-component {
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 480px) {
  .Right-side-component {
    width: 100%;
    overflow: hidden;
    background-color: #ece5dd;
  }
  .content-div {
    padding: 0px;
    background-color: #ece5dd;
  }
  .profile-container {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0px;
  }

  .profile-student-id-text-query-chat-screen {
    text-wrap: nowrap;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .profile-container-query-chat-screen-Container {
    display: flex;
    border-bottom: 1px solid #f4f5f7;
    background-color: #128c7e;
    padding-bottom: 5px;
    padding-left: 5px;
    justify-content: space-between;
  }
  .list-add-bttn {
    cursor: pointer;
    border: 1px solid #9d2764;
    background-color: #ffffff;
    border-radius: 5px;
    color: #9d2764;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 5px;
    height: auto;
    width: auto;
  }
}

.view-closed-query-button {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  background-color: #606060;
  color: #ffffff;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  border-radius: 20px;
}
.view-opened-query-button {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  background-color: #f7931d;
  color: #212226;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  border-radius: 20px;
}
.communication-management-content-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.query-feedback-left-container {
  overflow-y: auto;
  border-right: 1px solid #333333;
  height: calc(100vh - 115px);
  padding: 0;
}

.query-chatlist-message-input::placeholder {
  color: #7a7a7a;
}
.attachement-text-container {
  position: absolute;
  left: 10px;
  width: 85%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.close-attachment-icon {
  color: #050505;
  cursor: pointer;
}

.send-receive-message-text-date-underline {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #f69f3a;
  text-decoration: underline;
}
.send-receive-message-text-date {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 5px;
}

.no-chat-text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.mark-as-closed-button {
  letter-spacing: 0px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background-color: #283557;
  border: none;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
}
.attachement-text-container {
  position: absolute;
  left: 10px;
  width: 85%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.attachement-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #050505;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  text-decoration: none;
}
.close-attachment-icon {
  color: #050505;
  cursor: pointer;
}
.feedback-closed-on-text {
  font-size: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #606060;
}

.send-receive-message-text > a {
  color: #ffffff !important;
}
.attachment-color-query > a {
  color: blue !important;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.attachment-color-query > a:hover {
  color: rgb(255, 0, 0) !important;
}
.drop-here-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f7931d;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  border: 1px dashed #f7931d;
  font-size: 20px;
}

.container-chat-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  align-items: center;
}
.upload-icon {
  position: absolute;
  right: 70px;
}
@media screen and (min-width: 2026px) {
  .upload-icon {
    position: absolute;
    right: 100px;
  }
}

@media screen and (max-width: 768px) {
  .upload-icon {
    position: absolute;

    right: 70px;
  }
  .chat-component {
    padding: 0px;
  }
  .query-chat-container {
    padding: 0px 15px;
  }
  .query-chatlist-message-input-container {
    padding: 0px 15px;
    bottom: -365px;
  }

  .chat-content {
    height: calc(100vh - 402px);
  }

  .query-feedback-right-container {
    height: calc(100vh - 110px);
  }
  .query-chat-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow-y: scroll;
    padding-top: 10px;
    height: calc(100vh - 200px);
    overflow-anchor: auto;
    background-color: #ece5dd;
  }
}
.attachment-image > img {
  height: 250px;
}
