.sidebar {
  height: 100%;
  border-right: 1px solid #e6eff5;
  width: 240px;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.sidebar-logo-container {
  display: inline-flex;
  margin: 15px 25px 0 25px;
  gap: 10px;
  align-items: center;
}
/* .sidebar-logo1 {
  width: 65.46px;
  height: 80px;
}
.sidebar-logo2 {
  width: 79px;
  height: 66px;
  margin-top: 6px;
} */

.sidebar-nav {
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
}

.sidebar-item {
  margin-bottom: 15px;
  display: inline-flex;
  width: 100%;
  padding-left: 40px;
}

/* .sidebar-item a svg{
  width:25px;
  height:25px;
} */

.sidebar-link {
  text-decoration: none;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #3d3b3b;
  display: inline-flex;
  gap: 20px;
}
.sidebar-link {
  display: flex;
  margin-left: -5px;
  align-items: center;
}

.sidebar-text {
  margin-left: 5px;
}
.sidebar-text1 {
  margin-top: 10px;
  color: #1814f3;
}
.active-sidebar {
  border-left: 5px solid #1814f3;
  /* border-radius: 5px; */
}
