.Right-side-component{
    display:flex;
    flex-direction:column;
    align-items:initial;
    width:100%;
    height:100vh;
    overflow: hidden;
}

.content-div {
    height: calc(100vh - 80px);
    background-color:#F5F7FA ;
    padding:20px;
    scrollbar-width: none;
}
.content-div::-webkit-scrollbar {
    width: 0;
}
.whatsapp-mobile-view{
    height: 100%;
}