.reports-component {
  background-color: #ffffff;
  padding: 0px 25px 20px 25px;
  border-radius: 20px;
}

.reports-component-tab-bar {
  display: flex;
  flex-direction: row;
  gap: 40px;
  border-bottom: 1px solid #f4f5f7;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 15px 10px 0px 10px;
  background-color: #ffffff;
}

.reports-component-tab-bar div {
  padding-bottom: 10px;
  color: #718ebf;
  font-size: 16px;
  font-weight: 500;
}
.reports-component-active-tab-bar {
  border-bottom: 2px solid #9d2764;
  color: #9d2764 !important;
}
.reports-component-tab-bar-mobile {
  display: flex;
  flex-direction: row;
  gap: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.reports-component-tab-bar-mobile div {
  padding-bottom: 10px;
  color: #718ebf;
  font-size: 16px;
  font-weight: 500;
}
.reports-component-active-tab-bar-mobile {
  border-bottom: 2px solid #9d2764;
  color: #9d2764 !important;
}
.reports-component-main-content {
  display: flex;
  flex-direction: row;
}
.reports-component-profile-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 500px;
  width: 15%;
}

#reports-component-profile-edit-icon {
  border-radius: 50%;
  width: 15%;
  height: 15%;
  z-index: 2;
  position: absolute;
  bottom: 20%;
  right: 0%;
}

.reports-component-profile-button-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 42%;
  overflow: auto;
}
.reports-component-actions-heading {
  border-bottom: 2px solid #dadde8cc;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #718ebf;
}

.reports-component-profile-button-div button {
  border: 1px solid #1814f3;
  border-radius: 15px;
  height: 48px;
  width: 100%;
  background-color: white;
  color: #1814f3;
  font-weight: 600;
}
.reports-component-input-div {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.device-input-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 2% 2% 0 2%;
  gap: 5px;
}
.device-input-div label {
  font-size: 16px;
  font-weight: 400;
  color: #232323;
  text-align: left;
}

.device-input-div input {
  width: 98%;
  height: 40px;
  border: 1px solid #dfeaf2;
  border-radius: 15px;
  padding-left: 2%;
  font-size: 15px;
  font-weight: 500;
  color: #718ebf;
}
.device-input-div-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2% 2% 0 2%;
  gap: 5px;
}
.device-input-div-mobile label {
  font-size: 16px;
  font-weight: 400;
  color: #232323;
  text-align: left;
}

.device-input-div-mobile input {
  width: 98%;
  height: 40px;
  border: 1px solid #dfeaf2;
  border-radius: 15px;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #718ebf;
}
.report-head-mobile {
  display: flex;
  text-align: initial;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
