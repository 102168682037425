


.login-main-div {
    width: 100%;
    height: 100vh;
    background: linear-gradient(136.05deg, #9565DA 0%, #3074CD 96.81%), linear-gradient(0deg, #FAFAFA, #FAFAFA);
}

.login-div-1 {
    height: 100vh;
}

.iiot-circle {
    width: 31px;
    height: 33px;
    border-radius: 50%;
    border: 1px solid rgba(250, 100, 0, 1);
   
    background-color: rgba(255, 178, 127, 1);
}

.login-eye-image {
    position: absolute;
    color: gray;
    right: 30px;
    top: 42px;
}

.login-eye-slash-image {
    right: 45px;
    top: 42px;
    position: absolute;
    color: gray;
}



.login-label-1 {
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 1);
    margin-right: 215px;
}

.login-label-2 {
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 1);
    margin-right: 215px;
}



.login-input {
    width: 240px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid rgba(88, 83, 83, 1);
    padding-left: 15px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(88, 83, 83, 1);
}

.login-button {
    width: 83px;
    height: 35px;
    border-radius: 8px;
    border-top: 1px solid rgba(139, 134, 237, 1);
    background: linear-gradient(0deg, #776DD8, #776DD8), linear-gradient(0deg, #8B86ED, #8B86ED);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 254, 254, 1);
    padding: 5px 5px 6px 5px;
}




.login-container {
    width: 450px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(238, 238, 238);
    border-radius: 14px;
    background-color: rgba(255, 249, 249, 1);
    box-shadow: 2px 1px 16px 0px rgba(255, 255, 255, 0.1) inset ;
    
}



.sigin-text {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
}







.error-message-1 {
    position: absolute;
    top: 70px;
    font-size: 12px;
    color: red;
}

.error-message-2 {
    position: absolute;
    top: 70px;
    font-size: 12px;
    color: red;
}

@media screen and (max-width: 1023px) {
    .login-main-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login-div-1 {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
    }

    .login-div-2 {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
    }
}


@media screen and (max-width: 411px) {
    .login-container {
        width: 320px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgb(238, 238, 238);
        border-radius: 8px;
    }

    
}

@media screen and (max-width: 359px) {
    .login-container {
        width: 295px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgb(238, 238, 238);
        border-radius: 8px;
    }
}
